import { css } from '../../styles';

// The object overlayLayout are used to generate css for inline injection by scorponok
export const overlayLayout = {
  variants: {
    version: {
      imageLeft: {
        display: 'none',
        '&.mirror': {
          left: 'unset',
        },
        '&:not(.opinion &)[data-theme="nettavisen"]': {
          display: 'block',
          '& brick-pillbox': {
            display: 'none',
          },
        },
        '&[data-theme="alfa"], &[data-theme="charlie"]': {
          display: 'block',
          '& brick-pillbox': {
            display: 'none',
          },
        },
      },
      default: {},
    },
  },
};

export const overlayStyle = css({
  '--brick-teaser-display-overlay-pills': 'flex',
  display: 'var(--brick-teaser-display-overlay-pills)',
  gap: '$x1',
  position: 'absolute',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  bottom: 0,
  left: 0,
  right: 0,
  padding: '$teaserBoxInsetM',
  '& .premium-overlay': {
    flex: '1',
    order: 1,
    textAlign: 'right',
  },
  '& brick-pill': {
    order: 0,
  },
  '& brick-pillbox': {
    padding: '$none',
    flexShrink: '1',
  },
  '& .spillsenter-overlay': {
    background: '$baseInvertedBg',
    opacity: '$80',
    bottom: 0,
    color: '$baseInvertedFg',
    fontFamily: '$1',
    fontSize: '12px',
    height: 'auto',
    padding: '10px',
    left: 0,
    textAlign: 'left',
    width: '100%',
    zIndex: 1,
  },
  '@bp1': {
    '& .spillsenter-overlay': {
      padding: '1vw',
      fontSize: '1.2vw',
    },
  },
  '@bp2': {
    '& .spillsenter-overlay': {
      padding: '2vw',
      fontSize: '2.5vw',
    },
  },
  '@bp532': {
    padding: '$teaserBoxInsetL',
  },

  variants: {
    version: {
      imageLeft: {
        ...overlayLayout.variants.version.imageLeft,
      },
      default: {},
    },
    marker: {
      true: {
        padding: 0,
        '& .premium-overlay': {
          padding: '$teaserBoxInsetM',
        },
        '@bp532': {
          padding: 0,
          '& .premium-overlay': {
            padding: '$teaserBoxInsetL',
          },
        },
      },
      false: {
        'brick-carousel &': {
          padding: '$x2s',
          '@bp532': {
            padding: '$x2',
          },
        },
      },
    },
    teaserType: {
      teaser: {},
      story: {},
      review: {},
      opinion: {},
      commercial: {},
      video: {},
      gallery: {},
      alt: {},
      sport: {
        top: 0,
        flexDirection: 'column-reverse',
        background: '$supportiveSportGradient',
        '[data-group="ghost"] &': {
          '@container (inline-size >= 920px)': {
            padding: '$space$x3',
          },
        },
        padding: '$space$x3 $space$x3 0 $space$x3',
        '@bp532': {
          padding: '$space$x4 $space$x4 0 $space$x4',
        },
        'brick-carousel &': {
          padding: '$space$x2s $space$x2s 0 $space$x2s',
          '@bp532': {
            padding: '$space$x2 $space$x2 0 $space$x2',
          },
        },
        '& .premium-overlay': {
          flex: '1',
          order: 1,
          alignSelf: 'flex-end',
          textAlign: 'right',
        },

        '& brick-pill': {
          backgroundColor: '$baseNeutral00',
          color: '$baseFg',
          '&[data-version="breaking"]': {
            backgroundColor: '$pillNoneFilledBreakingBg',
            color: '$pillNoneFilledBreakingFg',
            '& svg': {
              color: '$pillNoneFilledPulseBg',
            },
          },
          '&[data-skin="sport"][data-filled="true"] + brick-pill': {
            '--brick--dividerColorLocal': 'var(--brick-colors-baseFg)',
          },
        },
        '& .sports-logo-overlay': {
          position: 'absolute',
          alignSelf: 'flex-start',
          display: 'flex',
          flexDirection: 'row',
          '--b-avatar-color-fg': 'var(--brick-colors-baseNeutral00)',
          '--b-avatar-color-border': 'var(--brick-colors-supportiveSportBg)',
          '--b-avatar-color-shape': 'var(--brick-colors-baseNeutral600)',
          '--b-avatar-padding': 'var(--brick-sizes-x1)',
          '& img': {
            position: 'relative',
          },
          '@container (inline-size < 200px)': {
            display: 'none',
          },
        },
      },
      feature: {},
    },
  },
  defaultVariants: {
    version: 'default',
    marker: false,
    theme: '',
  },
  compoundVariants: [
    {
      theme: 'nettavisen',
      teaserType: 'opinion',
      version: 'imageLeft',
      css: {
        display: 'flex',
        boxSizing: 'border-box',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        maxWidth: '100%',
        '& .premium-overlay': {
          flex: 'unset',
        },
      },
    },
    {
      teaserType: 'sport',
      version: 'imageLeft',
      css: {
        width: '100%',
        padding: '$space$x2',
        '& .sports-logo-overlay': {
          display: 'none',
        },
        '@bp532': {
          padding: '$space$x2',
        },
      },
    },
  ],
  '.solo-group &': {
    '& brick-pillbox': {
      display: 'none',
    },
    '& .body .top-pills': {
      display: 'block',
    },
  },
});

import { css } from '@amedia/brick-tokens';

export const plusStyles = {
  height: '$premiumIconM',
  width: '$premiumIconM',

  '@bp532': {
    height: '$premiumIconL',
    width: '$premiumIconL',
  },
};

export const plusAllStyles = {
  height: '34px',
  width: '34px',

  '@bp532': {
    height: '36px',
    width: '36px',
  },
};

export const premiumStyle = css({
  $$padding: '0px',
  display: 'inline-flex',
  boxSizing: 'border-box',

  borderRadius: '$premiumIcon',

  '& svg': {
    height: 'inherit',
    width: '100%',
  },

  variants: {
    theme: {
      alfa: {},
      bravo: {},
      charlie: {},
      nettavisen: {},
    },
    version: {
      plus: {},
      plusall: {
        ...plusAllStyles,
      },
    },
    filled: {
      true: {
        backgroundColor: '$premiumIconBg',
        color: '$premiumIconFg',
        padding: '$$padding',
      },
      false: {},
    },
  },
  compoundVariants: [
    {
      version: 'plus',
      theme: 'alfa',
      css: {
        ...plusStyles,
      },
    },
    {
      version: 'plus',
      theme: 'bravo',
      css: {
        ...plusStyles,
      },
    },
    {
      version: 'plus',
      theme: 'charlie',
      css: {
        height: '11px',
        width: '11px',
      },
    },
    {
      version: 'plus',
      theme: 'nettavisen',
      css: {
        ...plusStyles,
      },
    },
    {
      filled: true,
      theme: 'alfa',
      css: {
        $$padding: '2px',
        '@bp532': {
          $$padding: '3px',
        },
      },
    },
    {
      filled: true,
      theme: 'bravo',
      css: {
        $$padding: '2px',
        '@bp532': {
          $$padding: '3px',
        },
      },
    },
    {
      filled: true,
      theme: 'charlie',
      css: {
        $$padding: '2px',
        '@bp532': {
          $$padding: '3px',
        },
      },
    },
    {
      filled: true,
      theme: 'nettavisen',
      css: {
        $$padding: '2px',
      },
    },
  ],
});

import type { PillVersion } from '@amedia/brick-pill/dist/index.d.js';

import { TeaserType } from '../types';

const pillVersions: PillVersion[] = [
  'alt',
  'breaking',
  'countdown',
  'gallery',
  'plus',
  'video',
  'vignette',
  'podcast',
];

export const mapToPillVersion = (
  teaserType: TeaserType
): PillVersion | undefined => {
  const isValid = pillVersions.some((version) => version === teaserType);
  return isValid ? (teaserType as PillVersion) : undefined;
};

import { renderBrickAvatar } from '@amedia/brick-avatar/template';

import { altPill } from '../../pills/pills';
import { overlayStyle } from '../overlay-style.js';
import getPremiumIcon from '../../common/premiumIcon';
import { Overlay, SportsMeta, SportsTeam } from '../../../types';

import pillBoxTemplate from './pillBoxTemplate';

function getTeamAvatar(team: SportsTeam) {
  const dataImg = team.logo;
  const dataLetters = team.name ? team.name[0] : '';

  const dataAlttext = dataImg
    ? `Logo for laget ${team.name}`
    : dataLetters
    ? `Første bokstaven fra laget ${team.name} i et skjold`
    : '';

  return renderBrickAvatar({
    dataAlttext,
    dataImg,
    dataLetters,
    dataLettersShape: 'shield',
    dataSize: 'small',
    dataShape: 'circle',
  });
}

function getSportsLogoOverlay(sportsMeta: SportsMeta) {
  const homeTeamAvatar = sportsMeta.homeTeam
    ? getTeamAvatar(sportsMeta.homeTeam)
    : '';
  const awayTeamAvatar = sportsMeta.awayTeam
    ? getTeamAvatar(sportsMeta.awayTeam)
    : '';

  if (!(homeTeamAvatar.length > 0) && !(awayTeamAvatar.length > 0)) {
    return '';
  }

  return `<div class="sports-logo-overlay">
    ${homeTeamAvatar}
    ${awayTeamAvatar}
  </div>`;
}

export default function getTemplateStructure(data: Overlay) {
  const {
    premiumVersion,
    teaserType,
    theme,
    ghost,
    version = '',
    mirror,
    marker,
    pills,
  } = data;
  const overlayClasses = overlayStyle({
    version,
    marker,
    teaserType,
  });
  const mirrorClass = mirror ? 'mirror' : '';

  const sportsLogoOverlay =
    teaserType === 'sport' && data.sportsMeta
      ? getSportsLogoOverlay(data.sportsMeta)
      : '';

  if (ghost && teaserType === 'alt') {
    return `<div class="overlay_container ${overlayClasses}" data-theme="${theme}">${altPill(
      {
        version: 'alt',
        theme,
        text: ghost?.publicationName,
      }
    )}
</div>`;
  }

  const filled = true;

  const premiumTemplate: string = premiumVersion
    ? `<div class="premium-overlay">${getPremiumIcon({
        theme,
        premiumVersion,
        filled,
      })}</div>`
    : '';

  const structureTemplate = {
    alfa: [
      `<div class="overlay_container ${overlayClasses} ${mirrorClass}" data-theme="${theme}" >
        ${pillBoxTemplate(pills)}
        ${premiumTemplate}
        ${sportsLogoOverlay}
       </div>`,
    ],
    charlie: [
      `<div class="overlay_container ${overlayClasses} ${mirrorClass}" data-theme="${theme}">
        ${pillBoxTemplate(pills)}
        ${sportsLogoOverlay}
       </div>`,
    ],
    default: [
      `<div class="overlay_container ${overlayClasses}" data-theme="${theme}">
        ${pillBoxTemplate(pills)}
        ${sportsLogoOverlay}
      </div>`,
    ],

    nettavisen: [
      `<div class="overlay_container ${overlayClasses} ${mirrorClass}" data-theme="${theme}">
        ${pillBoxTemplate(pills)}
        ${premiumTemplate}
        ${sportsLogoOverlay}
       </div>`,
    ],
  };

  return (structureTemplate[theme] || structureTemplate.default).join('');
}

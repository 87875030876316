//data-chromatic="ignore" is used to signal to chromatic not to snapshot this element
export function getVideoPreview({
  url,
  previewUrl,
  title,
  imageElement,
}: {
  url?: string;
  previewUrl?: string;
  title: string;
  imageElement: string;
}) {
  let preview = '';

  if (previewUrl) {
    preview = `<div class="preview-img">
                  <img src="${previewUrl}" alt="${title}" loading="lazy" class="preview" data-chromatic="ignore">
                </div>`;
  } else if (url) {
    preview = `<div class="preview-video">
                  <video class="preview" autoplay muted loop playsinline>
                    <source src="${url}" type="video/mp4">
                  </video>
                </div>`;
  }

  return `
    <video-preview>
      ${preview}
      <div class="preview-image" data-preview-image data-testid="preview">
       ${imageElement}
      </div>
    </video-preview>`;
}

import { Teaser } from '../types';
import { getFeatureTemplate } from './feature/featureTemplate';
import { getOpinionTemplate } from './opinion/opinionTemplate';

import { getNoImageTemplateStructure } from './templates/noImageTemplate';
import { getImageTemplateStructure } from './templates/template';

interface ComponentTemplate {
  data: Teaser;
  useAutolayout: boolean;
  aoiBeta: boolean;
}

export const getTemplateStructure = (componentTemplate: ComponentTemplate) => {
  const teaserType = componentTemplate.data.teaserType;
  /**
   * This is a start to break down brick-teaser in several teaser types,
   * with the goal that all separate template will be self contained
   * with only some shared smaller components.
   *
   * All teaser types should greadually move into the switch
   *
   * Opinion is the first to be separated
   *
   */
  switch (teaserType) {
    case 'opinion':
      return getOpinionTemplate(componentTemplate);
    case 'feature':
      return getFeatureTemplate(componentTemplate);
  }
  if (!componentTemplate.data.body.hasImage) {
    return getNoImageTemplateStructure(componentTemplate);
  } else {
    return getImageTemplateStructure(componentTemplate);
  }
};

import { countdownStyle } from './countdown-style.js';

export default function countdownTemplate(data) {
  const { countdown, version, teaserType, body, theme } = data;

  if (!countdown?.date && countdown?.position !== 'bottom') return '';

  const { title, marker } = body;
  const { skin } = title;
  const { date, text } = countdown;
  const countdownSelector = 'brick-countdown-v0';

  const countdownMarkup = `
    <div class="countdown ${countdownStyle({
      version,
      teaserType,
      image: data.body.hasImage,
      skin,
      theme,
      marker,
    })}">
      <hr>
      <${countdownSelector}
        data-date="${date}"
        ${text ? `data-text="${text}"` : ''}
      ></${countdownSelector}>
    </div>
  `;
  return countdownMarkup;
}

import { renderBrickPillbox } from '@amedia/brick-pill/template';

import {
  vignettePill,
  breakingPill,
  countdownPill,
  plusPill,
  altPill,
} from '../../pills/pills';
import { mapToPillVersion } from '../../../utils/mapToPillVersion';
import { renderStrings } from '../../../utils/renderStrings';
import { BodyPills, PremiumVersion } from '../../../types';
import { mapToPillSkin } from '../../../utils/getSkin';

export function topPillsImageTemplate(
  data: BodyPills,
  premiumVersion?: PremiumVersion
) {
  const {
    vignette,
    breakingNews,
    teaserType,
    theme,
    countdown,
    marker,
    commercialCategory,
    skin,
    ghost,
  } = data;

  let pillSkin = mapToPillSkin({ skin, commercialCategory });

  const isOpinion = teaserType === 'opinion';

  if (isOpinion && !marker) {
    pillSkin = 'opinion';
  }

  const isPremium = Boolean(premiumVersion);

  const opinionLabel = isOpinion
    ? vignette?.opinionType || vignette?.vignette
    : undefined;
  const vignetteText =
    vignette?.vignette !== opinionLabel ? vignette?.vignette : undefined;

  const filled =
    marker || !!commercialCategory || (!!opinionLabel && theme === 'bravo');

  const isBreaking =
    (breakingNews?.breakingNews &&
      breakingNews?.breakingNewsPosition === 'vignette') ||
    false;
  const isCountdownPill = !!countdown?.position && !!countdown?.date;
  const pillVersion = mapToPillVersion(teaserType);

  const bodyPillBox = {
    breaking: '',
    opinion: '',
    vignette: '',
    countdown: '',
    premium: '',
    ...(isBreaking && {
      breaking: breakingPill({
        breakingNews,
        skin: pillSkin,
        theme,
        filled,
      }),
    }),
    ...(opinionLabel && {
      opinion: vignettePill({
        version: pillVersion,
        text: opinionLabel,
        skin: pillSkin,
        filled,
      }),
    }),
    ...(vignetteText && {
      vignette: vignettePill({
        version: pillVersion,
        text: vignetteText,
        skin: pillSkin,
        filled,
      }),
    }),
    ...(isCountdownPill && {
      countdown: countdownPill({
        version: pillVersion,
        countdown,
        skin: pillSkin,
        theme,
        filled,
      }),
    }),
    ...(isPremium && {
      premium: plusPill({
        version: premiumVersion,
        skin: pillSkin,
        filled,
        theme,
      }),
    }),
    altPill: altPill({
      version: 'alt',
      theme,
      text: ghost?.publicationName,
      filled: true,
    }),
  };

  const pillBoxChildren = renderStrings`${bodyPillBox.breaking} ${bodyPillBox.opinion} ${bodyPillBox.countdown} ${bodyPillBox.vignette} ${bodyPillBox.premium}`;

  const renderPills = pillBoxChildren.length > 0;

  if (teaserType === 'alt') {
    return `<div class="top-pills">
    ${renderStrings`${bodyPillBox.altPill}}`}
    </div>`;
  }

  return renderPills
    ? `<div class="top-pills">
  ${renderBrickPillbox({ filled }, pillBoxChildren)}
  </div>`
    : '';
}

import { css } from '../../styles';

//the object imageLayout are used to generate css for inline injection by scoponok
export const imageLayout = {
  variants: {
    version: {
      default: {
        '.opinion &': {
          '&[data-theme="alfa"], &[data-theme="charlie"]': {
            '& .overlay_container': {
              '--brick-teaser-display-overlay-pills': 'none',
            },
          },
        },
      },

      imageLeft: {
        paddingBottom: '0',
        boxSizing: 'border-box',
        '& .imagewrapper': {
          paddingTop: '0 !important',
          height: '100%',
        },
        '& .imagewrapper img': {
          width: 'auto',
          minWidth: '100%',
          maxHeight: '100%',
          objectFit: 'cover',
        },
        '& .imagewrapper .brick-teaser-byline img': {
          objectFit: 'cover',
        },
        '&.marker .overlay_container': {
          '--brick-teaser-display-overlay-pills': 'none',
        },

        '.opinion &': {
          '&[data-theme="alfa"], &[data-theme="charlie"]': {
            '& .overlay_container': {
              display: 'block',
            },
          },
        },
      },
    },
  },
};

export const imageStyle = css({
  backgroundColor: 'inherit',
  color: 'inherit',
  display: 'block',
  margin: 0,
  paddingBottom: '0',

  '& .imagewrapper': {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '$teaserImage',

    '& img, & video': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  '@bp1': {
    paddingBottom: 0,
  },
  '@bp2': {
    paddingBottom: 0,
  },
  variants: {
    aoiBeta: {
      true: {},
      false: {
        '& amedia-img': {
          display: 'block',
        },
        '& img, & video': {
          position: 'absolute',
          top: 0,
          left: 0,
        },
      },
    },
    version: {
      default: {
        ...imageLayout.variants.version.default,
      },
      imageLeft: {
        ...imageLayout.variants.version.imageLeft,
      },
    },
    theme: {},
    preview: {
      true: {
        '& video-preview': {
          display: 'block',
        },
        '& .preview-image.hide': {
          opacity: '0',
        },
        '& img.preview': {
          position: 'absolute',
          top: '0 !important',
          left: '0 !important',
          width: 'auto !important',
          width: '-moz-available !important',
          objectFit: 'cover',
          maxBlockSize: '-webkit-fill-available' /* Chrome, safari etc.*/,
          minBlockSize: '-webkit-fill-available' /* Chrome, safari etc.*/,
          minHeight: '100%',
          maxHeight: '100% !important',
          height: 'auto !important',
        },
        '&:hover': {
          '& .preview-image': {
            opacity: 0,
          },
        },
      },
    },
  },
  defaultVariants: {
    version: 'default',
    theme: '',
    preview: false,
  },
  '.solo-group &': {
    paddingBottom: 0,
    boxSizing: 'border-box',
  },
});

export const figureStyle = css({
  width: '100%',
  height: '100%',
  margin: 0,
});

export const imageWrapperStyle = css({
  height: '100%',
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
  borderRadius: '$teaserImage',
  minHeight: 'var(--focus-min-height)',
  maxHeight: 'var(--focus-max-height)',
  '& img': {
    objectFit: 'cover',
    objectPosition: 'left var(--focus-x) top var(--focus-y)',
    height: 'auto',
    // objectViewBox: 'inset(5% 10% 5% 5%)',
  },
});

import getPremiumIcon from '../../components/common/premiumIcon';
import { Teaser } from '../../types';
import footerTemplate from '../../components/footer/footer';
import { baseBodyStyle } from '../../components/body/body-style.js';
import teaserImageTemplate from '../../components/image/image';
import tipUsTemplate from '../../components/footer/tip-us';
import bylineTemplate from '../../components/byline/byline';

import { overlayBylineContainerStyle } from '../../components/byline/byline-style';
import { bubbleTemplate } from './bubbleTemplate';
import {
  opinionStyle,
  titleContainerStyle,
  premiumWrapper,
  pillContainer,
  imageOpinionLayoutStyle,
  noImageOpinionLayoutStyle,
  gradientStyle,
  imageWrapper,
} from './opinion-style.js';
import getTemplateStructure from '../../components/title/templates/structure';
import { plusPill, vignettePill } from '../../components/pills/pills';
import { renderBrickPillbox } from '@amedia/brick-pill/template';
import { renderStrings } from '../../utils/renderStrings';

interface ComponentTemplate {
  data: Teaser;
  useAutolayout: boolean;
  aoiBeta: boolean;
}

export const getOpinionTemplate = (componentTemplate: ComponentTemplate) => {
  const { byline, theme } = componentTemplate.data;
  const renderBubble = byline && (theme === 'alfa' || theme === 'charlie');

  if (renderBubble) {
    return bubbleTemplate(componentTemplate);
  }

  return getImageTemplateStructure(componentTemplate);
};

export const getImageTemplateStructure: (
  componentTemplate: ComponentTemplate
) => string = ({ data, useAutolayout, aoiBeta }: ComponentTemplate) => {
  const { body, theme, footer, byline } = data;

  const {
    url,
    image,
    version,
    mirror,
    marker,
    premiumVersion,
    title,
    vignette,
  } = body;
  const { skin } = title;
  let imageMarkup = '';
  const hasImage = !!image?.imageData;
  const bylineMarkup = byline
    ? bylineTemplate({
        byline,
        theme,
        hasImage,
      })
    : '';
  const hasByline = !!bylineMarkup;

  if (hasImage) {
    const imageTemplate = teaserImageTemplate({
      data: image,
      useAutolayout,
      aoiBeta,
      disableOverlay: true,
      skin,
    });

    if (hasByline) {
      imageMarkup = `<div class="${imageWrapper}">
        ${imageTemplate}
        <div class="${gradientStyle}"></div>
      </div>`;
    } else {
      imageMarkup = imageTemplate;
    }
  }

  const filledPills = theme === 'bravo' || theme === 'nettavisen';
  const pillsParams = {
    skin: marker && hasImage ? skin : 'opinion',
    theme,
    filled: filledPills,
  };

  const pluspill =
    theme === 'nettavisen' && !hasImage && premiumVersion
      ? plusPill({ ...pillsParams, version: 'plus' })
      : '';
  const pills = renderStrings`${vignettePill({
    ...pillsParams,
    text: vignette.opinionType,
  })} ${vignettePill({
    ...pillsParams,
    text: vignette.vignette,
  })} ${pluspill}`;
  const pillbox = renderBrickPillbox({ filled: pillsParams.filled }, pills);
  const pillsMarkup = pillbox
    ? `<div class="opinion-pills ${pillContainer({
        hasImage,
        theme,
        marker,
      })}">${pillbox}</div>`
    : '';

  const mirrorClass = mirror ? 'mirror' : '';
  const markerClass = marker ? 'marker' : '';
  const bylineClass = hasByline ? 'byline' : '';
  const pillsClass = bylineMarkup || pillsMarkup ? 'pills' : '';
  const premiumClass = premiumVersion ? 'premium' : '';

  const tipUsMarkup = tipUsTemplate(footer);

  const filled =
    premiumVersion === 'plusall' ||
    theme === 'alfa' ||
    theme === 'nettavisen' ||
    false;

  const premiumStyles = premiumWrapper({
    hasImage,
    hasByline,
    theme,
  });
  const premiumBadge =
    premiumVersion && !pillsMarkup.includes('plus')
      ? `<div class="premium_container ${premiumStyles}">${getPremiumIcon({
          theme,
          premiumVersion,
          filled,
        })}</div>`
      : '';

  const bodyStyles = opinionStyle({
    version,
    marker,
    hasByline,
    skin,
  });

  const layoutStyles = hasImage
    ? imageOpinionLayoutStyle({ version, theme })
    : noImageOpinionLayoutStyle({
        pills: !!(bylineMarkup || pillsMarkup),
        premium: !!premiumBadge,
        byline: hasByline,
        theme,
      });

  const overlayByline =
    imageMarkup && bylineMarkup
      ? `<div class="byline_wrapper ${overlayBylineContainerStyle}">${bylineMarkup}</div>`
      : '';

  const titleMarkup = getTemplateStructure(title, useAutolayout);

  return `<a href="${url}" itemprop="url" class="opinion_body ${mirrorClass} ${markerClass} ${pillsClass} ${premiumClass} ${bylineClass} ${baseBodyStyle} ${layoutStyles} ${bodyStyles}">
      ${imageMarkup}
      ${overlayByline || bylineMarkup}
      ${pillsMarkup}
      <div class="title_container ${titleContainerStyle({
        theme,
        marker,
        hasImage,
      })}">
        ${titleMarkup}
        </div>
      ${premiumBadge}
  </a>
  ${footerTemplate({ tipUsMarkup, skin, theme })}`;
};

import { css, backgroundColors } from '@amedia/brick-tokens';

export const bubbleTeaserStyle = css({
  '@property --b-teaser-color-bg': `{
    syntax: "<color>";
    inherits: true;
    initial-value: #fff;
  }`,
  '@property --b-teaser-color-fg': `{
    syntax: "<color>";
    inherits: true;
    initial-value: #000;
  }`,
  '--_b-opinion-color-bg':
    'var(--b-teaser-color-bg, var(--brick-colors-supportiveNoneBg, #fff))',
  '--_b-opinion-color-fg':
    'var(--b-teaser-color-fg, var(--brick-colors-supportiveNoneFg, 000))',
  backgroundColor: 'var(--_b-opinion-color-bg)',
  color: 'var(--_b-opinion-color-fg)',

  $$avatar: '$sizes$avatarM',
  $$arrow: 'calc(($space$teaserSkinInsetM / 4) + ($$avatar / 6))',
  container: 'teaser-content / inline-size',
  display: 'grid',
  gridTemplateColumns: 'auto auto $premiumIconM',
  gridTemplateRows: 'auto $$arrow $$avatar',
  gridTemplateAreas:
    '"bubble bubble bubble" "arrow . ." "byline byline premium"',
  padding: '$teaserYInsetM $teaserXInsetM',

  '@bp532': {
    $$arrow: 'calc(($space$teaserSkinInsetL / 4) + ($$avatar / 6))',
    gridTemplateColumns: 'auto auto $premiumIconL',
    padding: '$teaserYInsetL $teaserXInsetL',
  },
});

//the object bubbleLayout are used to generate css for inline injection by scoponok
export const bubbleLayout = {
  variants: {
    version: {
      imageLeft: {
        columnGap: '$teaserStackAM',
        rowGap: '$teaserStackBM',
        gridTemplateColumns: 'calc(34% + 3%) 1fr',
        gridTemplateRows: 'auto',
        gridTemplateAreas: '"image title" "image title"',
        '&.mirror': {
          gridTemplateColumns: '1fr calc(34% + 3%)',
          gridTemplateAreas: '"title image" "title image"',
        },
        '&.pills': {
          gridTemplateAreas: '"image pills" "image title"',
          gridTemplateRows: 'auto',
        },
        '&.pills.mirror': {
          gridTemplateAreas: '"pills image" "title image"',
          gridTemplateRows: 'auto',
        },
        gridFlow: 'row',
        position: 'relative',
        '& figure': {
          gridArea: 'image',
        },
        '& .title_container': {
          gridArea: 'title',
          boxSizing: 'border-box',
        },
        '& .bubble-pills': {
          paddingTop: '0px',
          '@bp532': {
            paddingTop: '0px',
          },
        },
        '@bp532': {
          columnGap: '$teaserStackAL',
          rowGap: '$teaserStackBL',
        },
      },
    },
  },
};

export const bubbleLayoutStyle = css({
  gridArea: 'bubble',

  '&.pills': {
    display: 'grid',
    gridTemplateAreas: '"pills" "title"',
    gridRowGap: '$teaserStackBM',
    '@bp532': {
      gridRowGap: '$teaserStackBL',
    },
  },
});

export const bubbleImageLayoutStyle = css({
  gridArea: 'bubble',
  display: 'grid',

  columnGap: '$teaserStackAM',
  rowGap: '$teaserStackBM',
  gridTemplateAreas: '"image" "title"',
  '&.pills': {
    gridTemplateAreas: '"image" "pills" "title"',
    gridTemplateRows: 'auto 1.7em 1fr',
  },
  '& figure': {
    gridArea: 'image',
  },

  '& .bubble-pills': {
    paddingTop:
      'calc($teaserContentYInsetM + ($teaserStackAM - $teaserStackBM))',
    '@bp532': {
      paddingTop:
        'calc($teaserContentYInsetL + ($teaserStackAL - $teaserStackBL))',
    },
  },

  variants: {
    version: {
      imageLeft: {
        ...bubbleLayout.variants.version.imageLeft,
      },
      default: {},
    },
  },
  '[data-group="ghost"] .solo-group &': {
    gridTemplateColumns: '30% 1fr auto',
    gridTemplateRows: 'auto',
    gridTemplateAreas: '"image title premium"',
    gridColumnGap: '$teaserStackAL',
    gridFlow: 'row',
    position: 'relative',
    '&.mirror': {
      gridTemplateColumns: '1fr 30%',
      gridTemplateAreas: '"title image"',

      '& .premium_container': {
        gridArea: 'image',
      },
    },
    '&.pills': {
      gridTemplateAreas: '"image pills" "image title"',
    },
    '&.pills.mirror': {
      gridTemplateAreas: '"pills image" "title image"',
    },
  },
});

export const bubbleStyle = css({
  '@property --b-opinion-bubble-color-bg': `{
    syntax: "<color>";
    inherits: true;
    initial-value: #f1eded;
  }`,
  '@property --b-teaser-color-fg': `{
    syntax: "<color>";
    inherits: true;
    initial-value: #000;
  }`,
  '--_bubble-skin-bg': 'var(--brick-colors-teaserBubbleBg, #f1eded)',
  '--_bubble-skin-fg': 'var(--brick-colors-teaserBubbleFg, #000)',

  '--_b-bubble-color-bg':
    'var(--b-opinion-bubble-color-bg, var(--_bubble-skin-bg))',
  '--_b-bubble-color-fg': 'var(--b-teaser-color-fg, var(--_bubble-skin-fg))',
  backgroundColor: 'var(--_b-bubble-color-bg)',
  color: 'var(--_b-bubble-color-fg)',
  padding: '$teaserBubbleInsetM',

  '@bp532': {
    padding: '$teaserBubbleInsetL',
  },

  'brick-carousel &': {
    padding: '$x2',
  },

  variants: {
    skin: {
      sport: {},
      none: {
        '--_bubble-skin-bg': 'var(--brick-colors-teaserBubbleBg)',
        '--_bubble-skin-fg': 'var(--brick-colors-teaserBubbleFg)',
      },
      black: {
        '--_bubble-skin-bg': 'var(--brick-colors-supportiveBlackBg)',
        '--_bubble-skin-fg': 'var(--brick-colors-supportiveBlackFg)',
      },
      'custom-one': {
        '--_bubble-skin-bg':
          'var(--custom-background-color-one, var(--brick-colors-supportiveCustomOneBg))',
        '--_bubble-skin-fg':
          'var(--custom-background-color-one-front, var(--brick-colors-supportiveCustomOneFg))',
      },
      'custom-two': {
        '--_bubble-skin-bg':
          'var(--custom-background-color-two, var(--brick-colors-supportiveCustomTwoBg))',
        '--_bubble-skin-fg':
          'var(--custom-background-color-two-front, var(--brick-colors-supportiveCustomTwoFg))',
      },
      'custom-three': {
        '--_bubble-skin-bg':
          'var(--custom-background-color-three, var(--brick-colors-supportiveCustomThreeBg))',
        '--_bubble-skin-fg':
          'var(--custom-background-color-three-front, var(--brick-colors-supportiveCustomThreeFg))',
      },
      highlight: {
        '--_bubble-skin-bg': 'var(--brick-colors-supportiveHighlightBg)',
        '--_bubble-skin-fg': 'var(--brick-colors-supportiveHighlightFg)',
      },
      opinion: {
        '--_bubble-skin-bg':
          'var(--opinion-background-color, var(--brick-colors-supportiveOpinionBg))',
        '--_bubble-skin-fg':
          'var(--opinion-color-front, var(--brick-colors-supportiveOpinionFg))',
      },
    },
  },
});

export const titleContainerStyle = css({
  gridArea: 'title',
  display: 'flex',
  flexDirection: 'column',
  gap: '$teaserStackTitleM',
  flex: '1',
  paddingBottom: '$teaserContentYInsetM',

  //@media over 532
  '@bp532': {
    gap: '$teaserStackTitleL',
    paddingBottom: '$teaserContentYInsetL',
  },
});

export const bubblePillsStyle = css({
  gridArea: 'pills',
});

export const arrowDown = css({
  '@property --b-opinion-bubble-color-bg': `{
    syntax: "<color>";
    inherits: true;
    initial-value: #f1eded;
  }`,
  '--_bubble-skin-bg': 'var(--brick-colors-teaserBubbleBg, #f1eded)',
  '--_b-bubble-color-bg':
    'var(--b-opinion-bubble-color-bg, var(--_bubble-skin-bg))',

  $$sizeBase: '$sizes$avatarM',
  gridRow: '2',
  position: 'relative',
  bottom: 0,
  left: 'calc($$sizeBase / 3)',
  width: 0,
  height: 0,
  borderLeft: 'calc($$sizeBase / 6) solid transparent',
  borderRight: 'calc($$sizeBase / 6) solid transparent',
  borderTop: `calc($$sizeBase / 6) solid var(--_b-bubble-color-bg)`,

  'brick-carousel &': {
    $$sizeBase: '$sizes$avatarS',
    left: 'calc(($$sizeBase / 3) + $space$x2)',
  },

  variants: {
    skin: {
      sport: {},
      none: { '--_bubble-skin-bg': '$colors$teaserBubbleBg' },
      black: {
        '--_bubble-skin-bg': '$colors$supportiveBlackBg',
      },
      'custom-one': {
        '--_bubble-skin-bg':
          'var(--custom-background-color-one, $supportiveCustomOneBg)',
      },
      'custom-two': {
        '--_bubble-skin-bg':
          'var(--custom-background-color-two, $supportiveCustomTwoBg)',
      },
      'custom-three': {
        '--_bubble-skin-bg':
          'var(--custom-background-color-three, $supportiveCustomThreeBg)',
      },
      highlight: {
        '--_bubble-skin-bg': '$colors$supportiveHighlightBg',
      },
      opinion: {
        '--_bubble-skin-bg':
          'var(--opinion-background-color, $supportiveOpinionBg)',
      },
    },
  },
});

export const bubbleFooterStyle = css({
  gridArea: 'byline',
  boxSizing: 'border-box',
  gap: '$teaserStackBM',
  $$spaceBase: '$space$teaserSkinInsetM',
  paddingTop: 'calc($$spaceBase / 4)',
  alignSelf: 'end',

  '@bp532': {
    $$spaceBase: '$space$teaserSkinInsetL',
    gap: '$teaserStackBL',
  },

  '@bp680': {
    gap: '$teaserStackBL',
  },

  'brick-carousel &': {
    paddingBottom: '$x2',
  },
});

export const premiumWrapper = css({
  gridArea: 'premium',
  placeSelf: 'end',
  display: 'flex',

  'brick-carousel &': {
    paddingRight: '$x2',
    paddingBottom: '$x2',
  },
});
